<template>
  <div class="bind-device">
    <div class="container">
      <div class="row">
        <div class="col">
            <h1>Bind your device</h1>
        </div>
      </div>

      <div class="row">
        <div class="col box-wrapper">
          <div class="step-box" data-step-index="1">
            <h2>Get the app</h2>
            <p>Get the companion app, it's free on the App Store:</p>
            <div class="text-center">
              <a href="https://lets.instl.app/ios" title="Get the companion App">
                <img src="/imgs/appstore-icon-us-black.svg" alt="Download from the App Store">
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col box-wrapper">
          <div class="step-box" data-step-index="1">
            <h2>Setup the companion app</h2>
            <p>Open the companion app, follow the setup instructions and when you are ready click the following button:</p>
            <p class="small text-left">
              By clicking the "Generate QR Code" button, you agree to our 
              <router-link :to="{name: 'legal', params: {contentName: 'privacy'}}" target="_blank">privacy policy</router-link> 
              and the 
              <router-link :to="{name: 'legal', params: {contentName: 'terms'}}" target="_blank">terms of use</router-link> 
              of the service.
            </p>
            <div class="text-center">
              <p>
                <button class="btn btn-primary" type="button" @click="generateQrCode">Generate QR Code</button><br>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="termsAccepted">
        <div class="col box-wrapper">
          <div class="step-box" data-step-index="1">
            <h2 id="scan-qr"> Scan the QR code</h2>
            <p>As last step scan the QR code that will appear below:</p>
            <p class="text-center">
              <VueQr v-if="qrContent" :text="qrContent" :size="512" />
              <LoadingSpinner v-if="waiting"/>
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import {Circle} from 'vue-loading-spinner'
import InstlCore from '@/services/instl-core'

const instl = InstlCore.getInstance();

export default {
  components: {
    VueQr,
    LoadingSpinner: Circle
  },
  data() {
    return {
      qrContent: null,
      waiting: false,
      termsAccepted: false,
    }
  },
  computed: {
    clientInfo() {
      return instl.clientInfo;
    }
  },
  methods: {
    generateQrCode() {
      this.waiting = true;
      this.termsAccepted = true;

      instl.registerClient()
        .then(() => {
          return instl.generateQrContent()
            .then((qrContent) => {
              this.qrContent = qrContent;
              this.waiting = false;

              if (window && window.location) {
                setTimeout(() => {
                  window.location.href = "#";
                  window.location.href = "#scan-qr";
                }, 300);
              }
            })
        .catch(() => {
          this.$notify({
            title: 'QR Code generation error',
            text: 'At this moment the QR Code generation is not possible, try again later.',
            type: 'error',
            duration: 8000
          });
        })
      })
    }
  },
  mounted() {
    
  }
}
</script>

<style lang="scss">
.bind-device .box-wrapper {
  margin: 0 15px 15px;
}
</style>
